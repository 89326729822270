.overflow-y-auto::-webkit-scrollbar {
    display: none;
  }

  .blur-lg {
    filter: blur(100px);
  }

  img:hover {
    transition: transform 0.3s ease-in-out;
    z-index: 20; /* Ensure it's above other content */
  }
  
  .image-box {
    height: 50px;
    overflow: hidden;
    margin-bottom: 4px;
  }
  
  @keyframes pulseBlur {
    0%, 100% {
      transform: scale(1);
      filter: blur(20px);
    }
    50% {
      transform: scale(1.05);
      filter: blur(50px);
    }
  }
  
  .blur-moving {
    animation: pulseBlur 1s infinite ease-in-out;
  }
  
  /* Background Overlay */
.loading-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 9999;
  animation: fadeIn 0.3s ease-in-out;
}

/* Loader Container */
.loader-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Spinner Animation */
.loader-spinner {
  width: 60px;
  height: 60px;
  border: 6px solid #3498db;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

/* Loading Text Animation */
.loader-text {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  animation: pulse 1.5s infinite ease-in-out;
}

/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Spinning Effect */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Pulsating Text */
@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
