.canvas-container {
  display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* Prevent scrollbars */
    background-color: #f8f9fa;
    /* Optional background color */
    border: 1px dashed #ccc;
    /* Optional border for visibility */
    border-radius: 4px;
    width: 100vw;
    /* Full width of the viewport */
    height: 100vh;
    /* Full height of the viewport */
    position: fixed;
}

.canvas-board {
  width: 80vw;
    height: 80vh;
  position: fixed;
  top: 0;
  left: 0;
}

.menu {
  background-color: #000000;

  .upload-section {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .overflow-tab {
    position: absolute;
    bottom: 20px;
    left: 90%;
    display: flex;
    align-items: right;
    gap: 10px;
    padding: 8px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .custom-file-upload {
    cursor: pointer;
    display: inline-block;
    padding: 6px 12px;
  }

  .prompt-panel-container {
    position: absolute;
    top: 20px;
    right: 15px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  }

  .prompt-textarea {
    width: 200px;
    height: 100px;
    resize: none;
    margin-bottom: 10px;
  }

  .generate-button {
    padding: 8px 15px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
  }

}

.design {
  --primary: #6965db;
  --primary-hover: #5753d0;
  --primary-bg-color: white;
  --secondary-bg-color: #f0efff;
  --selected-bg-color: #e0dfff;
  --primary-text-color: #27272c;
  --secondary-text-color: #b8b8b8;
  --highlight-color: #030064;
  --border-color: #e7e6f7;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --hover-bg-color: #e5e5fb;
  --panel-bg-color: #e7e7ee;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.textArea {
  position: fixed;
  font: 24px sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  overflow: hidden;
  white-space: pre;
  background: transparent;
  z-index: 2;
}

.parent>button {
  cursor: pointer;
}

.uploadArea {
  position: absolute;
  width: 40%;
    /* Responsive width */
    height: 30%;
    /* Responsive height */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 5px dashed #ccc;
  color: #aaa;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  transition: border-color 0.3s;
}

.uploadArea:hover {
  border-color: skyblue;
}

.uploadArea:hover::before,
.uploadArea:hover::after {
  content: '';
  position: absolute;
  background-color: skyblue;
  transition: background-color 0.3s;
}

.uploadArea:hover::before {
  width: 20px;
  height: 20px;
  top: -10px;
  left: -10px;
  border-top: 5px solid skyblue;
  border-left: 5px solid skyblue;
}

.uploadArea:hover::after {
  width: 20px;
  height: 20px;
  bottom: -10px;
  right: -10px;
  border-bottom: 5px solid skyblue;
  border-right: 5px solid skyblue;
}