/* ======= General Studio Styling ======= */
.studio-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ======= Fullscreen Mode ======= */
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

/* ======= Back Button Styling ======= */
.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  z-index: 100;
  transition: background 0.3s;
}

.back-button:hover {
  background: rgba(0, 0, 0, 0.9);
}

/* ======= Floating Controls Panel ======= */
.controls-overlay {
  position: absolute;
  top: 50px;
  right: 20px;
  width: 220px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.controls-overlay h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

/* ======= Control Buttons ======= */
.controls-overlay button {
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.controls-overlay button:hover {
  background: #0056b3;
}

/* ======= Range Sliders for Light Intensity ======= */
.controls-overlay label {
  font-size: 14px;
  color: #333;
  margin-bottom: 4px;
}

.controls-overlay input[type="range"] {
  width: 100%;
  accent-color: #007bff;
}

/* ======= Color Picker for Light Color ======= */
.controls-overlay input[type="color"] {
  width: 100%;
  height: 30px;
  border: none;
  padding: 0;
  cursor: pointer;
  background: none;
}