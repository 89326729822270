.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
}

.gallery-title {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 32px;
    color: #333;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
}

.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 16px;
    color: #333;
}

.card-description {
    font-size: 1rem;
    color: #666;
    margin: 0 16px 16px;
}

.card-link {
    display: block;
    text-align: center;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 0 0 8px 8px;
    transition: background-color 0.3s ease;
}

.card-link:hover {
    background-color: #0056b3;
}
.design-thumbnail {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.thumbnail-stack {
    position: relative;
    width: 100%;
    height: 100%;
}

.thumbnail-image {
    position: absolute;
    width: 90%;
    height: 90%;
    object-fit: cover;
    border: 2px solid #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.thumbnail-image.top {
    z-index: 3;
    top: 0;
    left: 0;
}

.thumbnail-image.middle {
    z-index: 2;
    top: 10px;
    left: 10px;
}

.thumbnail-image.bottom {
    z-index: 1;
    top: 20px;
    left: 20px;
}

.design-thumbnail:hover .thumbnail-image.middle {
    transform: translate(10px, -10px);
}

.design-thumbnail:hover .thumbnail-image.bottom {
    transform: translate(20px, -20px);
}

.design-name-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    text-align: center;
    padding: 4px 0;
    z-index: 4;
}